@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap');

body * {
  margin: 0;
  font-family: 'Mulish', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.modal-img {
  display: flex;
  align-items: center;
  justify-content: center;
}

.list-item-outline {
  border: 1px !important;
  border-style: solid !important;
  border-color: #9588e8 !important;
  border-radius: 50px !important;
  color: #9588e8 !important;
}

.list-item {
  background-color: #9588e8 !important;
  border-radius: 50px !important;
  color: white !important;
}

.list-item-child {
  color: #9588e8 !important;
  border-top: 0 !important;
  border-bottom: 0 !important;
  border-right: 0 !important;
  border-left: 4px !important;
  border-left-color: #9588e8 !important;
  border-style: solid !important;
}

.list-item-child-not-act {
  border-top: 0 !important;
  border-bottom: 0 !important;
  border-right: 0 !important;
  border-left: 4px !important;
  border-left-color: #dedee2 !important;
  border-style: solid !important;
}

.MuiInputBase-root {
  border-radius: 10px !important;
}

.ltb {
  color: #7c7e92;
  padding-top: 10px;
  font-weight: 700 !important;
  display: inline-block;
}

.ltb-p0 {
  color: #7c7e92;
  font-weight: 700 !important;
  display: inline-block;
}

.rtb {
  display: inline-block;
  font-weight: bold !important;
}

.grey {
  color: #7c7e92 !important;
  font-weight: 700 !important;
}

.MuiTableRow-root:hover { 
  background: #efedfc !important;
}

.gbr {
  border: 1 !important;
  border-width: 1px;
  border-style: solid;
  border-color: #e5e5e9 !important;
  border-radius: 25px !important;
}

.vbr {
  border: 1 !important;
  border-width: 1px;
  border-style: solid;
  border-color: #a297eb !important;
  border-radius: 25px !important;
}

.grey-block {
  border: 1 !important;
  border-width: 1px;
  border-style: solid;
  border-color: #f6f6f6 !important;
  border-radius: 25px !important;
  background-color: #f6f6f6 !important;;
}

.player-wrapper {
  position: relative;
  padding-top: 50.00%; /* 720 / 1280 = 0.5625 */
  border-radius: 5px;
  padding-bottom: 20px;
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 20px;
  overflow: hidden;
}

input.checked {
  background-color: #007a7e;
  position: relative;
}

input.checked::before {
  content: "2714";
  font-size: 1.5em;
  color: #fff;
  position: absolute;
  right: 1px;
  top: -5px;
}

.checkbox-wrapper input[type="checkbox"]:disabled {
  border-color: #c0c0c0;
  background-color: #c0c0c0;
}

.checkbox-wrapper input[type="checkbox"]:disabled + span {
  color: #c0c0c0;
}

.checkbox-wrapper input[type="checkbox"]:focus {
  box-shadow: 0 0 20px #007a7e;
}

.container {
  margin: 0 auto;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-auto-rows: 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
}
